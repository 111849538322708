<template>
    <div class="loader" v-bind:class="{ active: loader }">
        <div class="lds-dual-ring"></div>
    </div>
    <div class="site-title-container">
        <a class="site-title" href="/">
        </a>
        <div>
            <div class="preset_area">
                <div class="preset_area_item"><select name="" id="" v-model="presets_select">
                        <option :value="false">Выберите пресет</option>
                        <option v-for="preset in presets" :key="preset.presetID" :selected="true" :value="preset.presetID">{{preset.name}}</option>
                    </select></div>
                <div class="preset_area_item">
                    <input type="search" v-model="savepreset" class="input"> <a class="button button__add_preset" @click="savePreset">Сохранить пресет</a>
                    <div class="preset_area_item"> <a class="button remove" @click="deletePreset" v-if="filterParams['preset']">Удалить пресет</a></div>
                </div>
            </div>
        </div>
    </div>
    <div class="filter">
        <multiselect v-model="selected['codes']" id="codes" label="name" track-by="codeID" placeholder="ОКВЭД" open-direction="bottom" :options="data.codes" :multiple="true" :searchable="true" :loading="isLoading['codes']" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="300" :limit="6" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="findCodes($event,'codes')" @select="onSelect" @remove="onRemove">
            <template slot="clear">
                <div class="multiselect__clear" v-if="selectedCodes.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                <span slot="noResult">No results were found.</span>
                <span slot="noOptions">No results were found.</span>
            </template>
        </multiselect>
        <br />
        <multiselect v-model="selected['regions']" id="regions" label="name" track-by="name" placeholder="РЕГИОН" open-direction="bottom" :options="data.regions" :multiple="true" :searchable="true" :loading="isLoading['regions']" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="300" :limit="6" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="findRegions" @select="onSelect" @remove="onRemove">
            <template><span class="custom__tag"><span>{{ option.name }}</span></span></template>
            <template>
                <div class="multiselect__clear" v-if="selectedRegions.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
            </template><span>Oops! No elements found. Consider changing the search query.</span>
        </multiselect>
        <br />
        <multiselect v-model="selected['cities']" id="regions" label="name" track-by="name" placeholder="ГОРОД" open-direction="bottom" :options="data.cities" :multiple="true" :searchable="true" :loading="isLoading['cities']" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="300" :limit="6" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="findCities" @select="onSelect" @remove="onRemove">
            <template><span class="custom__tag"><span>{{ option.name }}</span></span></template>
            <template>
                <div class="multiselect__clear" v-if="selectedCities.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
            </template><span>Oops! No elements found. Consider changing the search query.</span>
        </multiselect>
    </div>
    <div class="preset_area"><a @click="getReport('tsv')" class="button">tsv</a><a @click="getReport('xls')" class="button">xls</a>&nbsp;&nbsp;<a @click="getStat()" class="button">Stat</a></div>
    <div class="companies">
        <input type="search" v-model="filter">
        <table class="companies" v-if="items.length">
            <tr>
                <th>ИНН</th>
                <th>ОГРН</th>
                <th class="sorted" @click="sort('created')">Создание</th>
                <th class="r sorted" @click="sort('taxes')">Налоги</th>
                <th style="width: 80%;">Название</th>
                <th>Контакт</th>
                <th>Город</th>
                <th>Регион</th>
            </tr>
            <tr v-for="company in items" :key="company.companyID" class="company-item" v-bind:class="{ inactive: company['active']!=1 }" @click="getCompanyInfo(company['companyID'])">
                <td>{{company['inn']}}</td>
                <td>{{company['ogrn']}}</td>
                <td v-if="company.active==1" class="nw">
                    {{company['created']}}
                </td>
                <td v-if="company.active!=1" class="nw">
                    <s :title="company.destroyed">{{company['created']}}</s>
                </td>
                <td class="r">{{company['tax']}}</td>
                <td :title="company['title_full']">{{company['title_short']}}</td>
                <td>{{company['contacts']}}</td>
                <td class="nw">{{company['city']}}</td>
                <td>{{company['region']}}</td>
            </tr>
        </table>
        <div class="nav" v-if="pagesCount">
            <a class="nav_item nav_item__next" @click="prevPage" v-if="this.filterParams['page']>1">Previous</a>
            <a class="nav_item nav_item__next" v-if="this.filterParams['page']>1" @click="setPage(1)">First</a>
            <a v-for="page in pagesCount" :key="page" class="nav_item" @click="setPage(page)" v-bind:class="{ active: (page==this.filterParams['page']) }">{{page}}</a>
            <a class="nav_item nav_item__next" @click="setPage(-1)" v-if="this.filterParams['page']!=pagesCount">Last</a>
            <a class="nav_item nav_item__next" @click="nextPage" v-if="this.filterParams['page']!=pagesCount">Next</a>
        </div>
        debug: {{this.debugger}}
        <br><br><br><br>
    </div>
    <modal @close="toggleModal" :modalActive="modalActive">
        <div class="modal-content">
            <h1 class="modal-header">{{modalTitle}}</h1>
            <div class="modal-content">
                <div class="overall" v-if="modalKind=='single'">
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">title_full:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['title_full']}}</div>
                    </div>
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">active:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['active']}}</div>
                    </div>
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">created:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['created']}}</div>
                    </div>
                    <div class="company-detail-item" v-if="this.companyData['base']['active']==0">
                        <div class="company-detail-item-title">destroyed:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['destroyed']}}</div>
                    </div>
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">exported:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['exported']}}</div>
                    </div>
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">address_code:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['address_code']}}</div>
                    </div>
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">base:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['base']}}</div>
                    </div>
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">ceo:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['ceo']}} ({{this.companyData['base']['ceo_inn']}})</div>
                    </div>
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">region:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['region']}} ({{this.companyData['base']['region_code']}})</div>
                    </div>
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">city:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['city']}}</div>
                    </div>
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">main_code:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['main_code']}}</div>
                    </div>
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">ogrn:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['ogrn']}}</div>
                    </div>
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">email:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['email']}}</div>
                    </div>
                    <div class="company-detail-item">
                        <div class="company-detail-item-title">tel:</div>
                        <div class="company-detail-item-value">{{this.companyData['base']['tel']}}</div>
                    </div>
                    <hr>
                    <ul class="detail-list">
                        <li v-for="(items, detail) in this.companyData['details']" v-bind:key="detail" class="detail-list-item">
                            <div class="detail-list-sub">{{detail}}</div>
                            <ul>
                                <li v-for="(item, index) in items" v-bind:key="index">
                                    <div v-if="typeof item =='string'">{{index}} - {{item}}</div>
                                    <div v-if="typeof item !='string'">{{index}}</div>
                                    <ul v-if="typeof item == 'object'">
                                        <li v-for="(item1, index1) in item" v-bind:key="item1">
                                            <div v-if="typeof item1 =='string'">{{index1}} - {{item1}}</div>
                                            <div v-if="typeof item1 !='string'">{{index1}}</div>
                                            <ul v-if="typeof item1 == 'object'">
                                                <li v-for="(item2, index2) in item1" v-bind:key="item2">
                                                    <div v-if="typeof item2 =='string'">{{index2}} - {{item2}}</div>
                                                    <div v-if="typeof item2 !='string'">{{index2}}</div>
                                                    <ul v-if="typeof item2 == 'object'">
                                                        <li v-for="(item3, index3) in item2" v-bind:key="item3">
                                                            <div v-if="typeof item3 =='string'">{{index3}} - {{item3}}</div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="overall" v-if="modalKind!='single'">
                    <div v-if="overall">{{ overall['all'] }}</div>
                    <div v-if="overall">{{ overall['active'] }}</div>
                    <div v-if="overall">{{ overall['likvidated'] }}</div>
                    <div v-if="overall">{{ overall['contacts'] }}</div>
                    <div v-if="overall">{{ overall['taxes'] }}</div>
                    <div v-if="overall">{{ overall['summ'] }}</div>
                    <div v-if="overall">{{ overall['mediana'] }}</div>
                    <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
                    <div class="chart_filter">
                        <span class="chart_filter_item" v-bind:class="{ active: (chart_kind=='months') }" v-on:click="changeChart('months')">months</span>
                        <span class="chart_filter_item" v-bind:class="{ active: (chart_kind=='years') }" v-on:click="changeChart('years')">years</span>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </modal>
</template>
<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios';

import VueApexCharts from "vue3-apexcharts";
import Modal from "@/components/modal.vue";
import { ref } from "vue";
export default {
    name: 'Home',
    components: {
        Multiselect,
        Modal,
        apexchart: VueApexCharts,
    },
    setup() {
        const modalActive = ref(false);

        const toggleModal = () => {
            modalActive.value = !modalActive.value;

        }
        return { modalActive, toggleModal }
    },
    created() {
        this.loader = false;
    },
    mounted() {
        if (this.$route.query.preset) {
            this.presets_select = this.$route.query.preset;
            // this.filterParams = this.$route.query;
            this.filterParams['page'] = this.$route.query.page;
            this.filterParams['sort'] = this.$route.query.sort;
            this.filterParams['dir'] = this.$route.query.dir;
            this.getData();
        }
        this.getPresets();

    },
    watch: {
        presets_select() {
            this.setPreset(this.presets_select);
        },
        filter(val) {

            if (typeof window.SEARCH_TIMEOUT !== 'undefined') {
                clearTimeout(window.SEARCH_TIMEOUT);
            }
            window.SEARCH_TIMEOUT = setTimeout(() => {
                this.filterParams['search_query'] = val;
                this.getData();
                console.info(val, 'val');
            }, 300);


        },
    },
    computed: {
        // pagesCount() {
        //     let pages = [];
        //     let start = 0;
        //     let total = Math.ceil(this.items.length / this.pageSize);
        //     let end = total;

        //     // this.totalPages = total;

        //     // if (total < 10) end = total;
        //     if (this.filterParams['page']+10>total){
        //        end=total;
        //        start = end -10;
        //     }
        //     if (this.filterParams['page']+10 < total) {
        //       start = this.filterParams['page'];
        //       end = start + 10;
        //       // alert(1)
        //     }

        //     for (var i = start; i <= end; i++)
        //      {
        //       pages.push(i);
        //      }

        //     // for (var i1 = total-10; i1 <= total; i1++)
        //     //  {
        //     //   console.info(i1, '---')
        //     //   pages.push(i1);
        //     //  }
        //      console.info(start, end, this.filterParams['page'], total);
        //      return pages;
        //     // return Math.ceil(this.items.length / this.pageSize);
        //     // return this.items.slice(this.filterParams['page']*this.pageSize, this.filterParams['page']*this.pageSize +);
        // },
        // filteredCompanies() {
        //     return this.items.filter(c => {
        //         if (this.filter == '') return true;
        //         return c.title_full.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
        //     })
        // },
        // sortedCats() {
        //     // sorted
        //     return [...this.filteredCompanies];
        //     // return [...this.filteredCompanies].sort((a, b) => {
        //     //     let modifier = 1;
        //     //     if (this.currentSortDir === 'desc') modifier = -1;
        //     //     if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        //     //     if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        //     //     return 0;
        //     // }).filter((row, index) => {
        //     //     let start = (this.filterParams['page'] - 1) * this.pageSize;
        //     //     let end = this.filterParams['page'] * this.pageSize;
        //     //     if (index >= start && index < end) return true;
        //     // });
        // }
    },

    data() {
        return {
            modalTitle: "none",
            modalContent: ".",
            totalPages: 0,
            modalKind: false,
            companyData: {},
            companyID: false,
            presets_select: false,
            nowrequest: false,
            savepreset: '',
            filter: '',
            debugger: {},
            pages: 30,
            presets: {},
            // currentPage: 1,
            loader: true,
            report: false,
            url_data: {},
            getFullStat: false,
            selected: { 'codes': [], 'regions': [], 'cities': [] },
            data: { 'codes': [], 'regions': [], 'cities': [] },
            isLoading: { 'codes': false, 'regions': false, 'cities': false },
            apiUrl: 'https://egrul.meta.zamsha.ai/api/',
            presetUrl: 'https://egrul.meta.zamsha.ai/api/presets/',
            overall: {},
            items: [],
            months: {},
            stat: {},
            filterParams: { preset: false, page: 1, 'sort': 'name', 'dir': 'asc' },
            // preset: false,
            chart_kind: 'months',
            created: {},
            destroyed: {},
            params: { 'preset': false },

            series: [],
            chartOptions: {
                chart: {
                    height: 500,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ['#28a745', '#dc3545'],
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'Количество компаний',
                    align: 'left'
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: 'По месяцам'
                    }
                },
                yaxis: {
                    title: {
                        text: 'Действия'
                    },
                    // min: 5,
                    // max: 40
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5
                }
            },
        }
    },

    methods: {
        getCompanyInfo(id) {
            this.companyID = id;
            let url = this.apiUrl + 'companies/single/';
            this.getData(url);

        },
        getReport: function(kind) {
            this.report = kind;
            let url = this.apiUrl + 'reports/' + kind + '/';
            this.getData(url);
        },
        getStat: function() {
            this.getFullStat = true;
            this.modalKind = 'overall';
            this.getData();
        },

        getPresets: function() {
            let that = this;
            this.selected = { 'codes': [], 'regions': [], 'cities': [] };
            axios.get(this.presetUrl + 'get/', {})
                .then(function(response) {
                    that.presets = response['data']['presets'];
                })
                .catch(function(error) {
                    console.info(error)
                })
                .finally(function() {});

        },

        deletePreset: function() {
            this.loader = true;
            // var formData = new FormData();
            // formData.append('preset', this.presets_select);
            let that = this;
            axios.get(this.apiUrl + 'presets/delete/' + this.presets_select + '/', {})
                .then(function(response) {

                    if (response['data']['ok']) {
                        that.presets = response['data']['presets'];
                        that.presets_select = false;
                        that.filterParams = {};
                    } else {
                        // alert(response['data']['error']);
                    }

                })
                .catch(function(error) {
                    console.info(error)
                })
                .finally(function() {
                    that.loader = false;
                });

        },

        savePreset: function() {
            let rows = { 'codes': [], 'regions': [], 'cities': [] };
            this.loader = true;
            var i = 0;
            var kind = '';
            for (kind in this.selected) {
                if (this.selected[kind]) {
                    for (i = 0; i <= this.selected[kind].length; i++) {
                        if (this.selected[kind][i]) {
                            if (kind === 'codes')
                                rows[kind].push(this.selected[kind][i]['codeID'])
                            else
                                rows[kind].push(this.selected[kind][i]['name'])
                        }

                    }

                }
            }


            var formData = new FormData();
            let row = rows;
            row['name'] = this.savepreset;
            formData.append('row', JSON.stringify(row));
            let that = this;
            axios.post(this.apiUrl + 'presets/save/', formData)
                .then(function(response) {

                    if (response['data']['ok']) {
                        that.presets = response['data']['presets'];
                        that.presets_select = response['data']['currentPresetID'];
                    } else {
                        alert(response['data']['error']);
                    }

                })
                .catch(function(error) {
                    console.info(error)
                })
                .finally(function() {
                    that.loader = false;
                });

        },
        sort: function(s) {
            if (s === this.filterParams['sort']) {
                this.filterParams['dir'] = this.filterParams['dir'] === 'asc' ? 'desc' : 'asc';
            }
            this.filterParams['sort'] = s;
            this.getData();
        },

        setPage: function(page) {
            if (page == -1)
                this.filterParams['page'] = Math.ceil(this.items.length / this.pageSize);
            else
                this.filterParams['page'] = page;
            this.getData();
        },
        nextPage: function() {
            if ((this.filterParams['page'] * this.pageSize) < this.items.length) this.filterParams['page']++;
        },
        prevPage: function() {
            if (this.filterParams['page'] > 1) this.filterParams['page']--;
        },

        onRemove() {
            let that = this;
            this.filterParams['page'] = 1;
            this.filterParams['preset'] = false;
            setTimeout(function() {
                console.info(that.selected);
                that.getData();
            }, 500)
        },
        changeChart(kind) {
            this.chart_kind = kind;
            this.updateChart();
        },
        updateChart() {
            if (!this.stat.months || !this.stat.months.list) return;
            if (this.chart_kind == 'months') {
                this.chartOptions = {
                    xaxis: {
                        categories: this.stat.months.list,
                        title: {
                            text: 'По месяцам'
                        }
                    }
                };
                this.series = [{
                        name: "Новые",
                        data: this.stat.months.created
                    },
                    {
                        name: "Ликвидированы",
                        data: this.stat.months.destroyed
                    }
                ]
            }

            if (this.chart_kind == 'years') {
                this.chartOptions = {
                    xaxis: {
                        categories: this.stat.years.list,
                        title: {
                            text: 'По годам'
                        }
                    }
                };
                this.series = [{
                        name: "Новые",
                        data: this.stat.years.created
                    },
                    {
                        name: "Ликвидированы",
                        data: this.stat.years.destroyed
                    }
                ]
            }
        },

        changePreset(preset) {
            let id = preset.target.selectedOptions[0].value;
            console.info(preset, id);
        },

        setPreset(preset) {
            this.filterParams['preset'] = preset;
            this.filterParams['page'] = 1;

            // let url = this.presetUrl + 'single/' + preset + '/';
            this.getData();

            // this.$router.push({ path: '/', query: { preset: preset } })
        },
        limitText(count) {
            return `and ${count} other`
        },

        findCities(query) {
            this.Request(query, 'cities');
        },


        findRegions(query) {
            this.Request(query, 'regions');
        },

        getData(url) {
            let rows = { 'codes': [], 'regions': [], 'cities': [] };
            this.loader = true;
            var i = 0;
            var kind = '';
            for (kind in this.selected) {
                if (this.selected[kind]) {
                    for (i = 0; i <= this.selected[kind].length; i++) {
                        if (this.selected[kind][i]) {
                            if (kind === 'codes')
                                rows[kind].push(this.selected[kind][i]['codeID'])
                            else
                                rows[kind].push(this.selected[kind][i]['name'])
                        }

                    }

                }
            }
            this.nowrequest = true;
            var formData = new FormData();
            formData.append('regions', JSON.stringify(rows['regions']));
            formData.append('cities', JSON.stringify(rows['cities']));
            formData.append('codes', JSON.stringify(rows['codes']));
            formData.append('filterParams', JSON.stringify(this.filterParams));
            formData.append('report', this.report);
            formData.append('getFullStat', this.getFullStat);
            formData.append('companyID', this.companyID);





            let that = this;
            let newurl = this.apiUrl + 'get/';
            if (url) newurl = url;
            axios.post(newurl, formData)
                .then(function(response) {
                    if (response['data']['report_url']) location.href = 'https://egrul.meta.zamsha.ai/' + response['data']['report_url'];
                    // return;

                    if (response['data']['companies'] && response['data']['companies']['overall'])
                        that.overall = response['data']['companies']['overall'];

                    if (response['data']['companies'] && response['data']['companies']['rows'])
                        that.items = response['data']['companies']['rows'];
                    else if (that.filterParams['search_query'] && !response['data']['report_url'])
                        that.items = [];

                    if (response['data']['title'])
                        that.modalTitle = response['data']['title'];

                    if (response['data']['modalKind'] && response['data']['modalKind'] == 'single') {
                        that.modalKind = 'single';
                        that.companyData = response['data'];
                        that.modalActive = true;
                    }

                    if (response.data.cities)
                        that.selected['cities'] = response.data.cities;

                    if (that.getFullStat)
                        that.modalActive = true;

                    if (response.data.regions)
                        that.selected['regions'] = response.data.regions;

                    if (response.data.codes)
                        that.selected['codes'] = response.data.codes;

                    if (response.data.companies && response.data.companies.stats)
                        that.stat = response.data.companies.stats;

                    that.debugger = response.data.debugger
                    that.pagesCount = response.data.pagesCount;


                })
                .catch(function(error) {
                    console.info(error)
                })
                .finally(function() {
                    that.loader = false;
                    that.nowrequest = false;
                    that.report = false;
                    that.getFullStat = false;
                    that.$router.push({ path: '/', query: {} })

                    that.$router.push({ path: '/', query: that.filterParams })
                    that.updateChart();
                });


        },
        Request(query, type) {
            let rows = { 'codes': [], 'regions': [], 'cities': [] };
            if (this.nowrequest) return;

            var i = 0;
            var kind = '';
            for (kind in this.selected) {
                if (this.selected[kind]) {
                    for (i = 0; i <= this.selected[kind].length; i++) {
                        if (this.selected[kind][i]) {
                            if (kind === 'codes')
                                rows[kind].push(this.selected[kind][i]['codeID'])
                            else
                                rows[kind].push(this.selected[kind][i]['name'])
                        }

                    }

                }
            }

            this.isLoading[type] = true;
            var that = this;
            var formData = new FormData();
            formData.append('kind', type);
            formData.append('query', query);
            formData.append('regions', JSON.stringify(rows['regions']));
            formData.append('cities', JSON.stringify(rows['cities']));
            formData.append('codes', JSON.stringify(rows['codes']));

            axios.post(this.apiUrl + "suggest/", formData)
                .then(function(response) {

                    if (response['data']['selected']) {
                        that.selected['codes'] = response['data']['selected']['codes'];
                    }
                    if (response['data']['ok']) {
                        that.data[type] = response['data']['results'];

                    }

                    that.filterParams['page'] = 1;
                    // if (type == 'codes') {
                    //     that.data['regions'] = [];
                    //     that.selected['regions'] = [];
                    //     that.selected['cities'] = [];
                    //     that.data['cities'] = [];
                    // }

                    // if (type == 'regions') {
                    //     that.data['cities'] = [];
                    //     that.selected['cities'] = [];
                    // }

                    that.isLoading[type] = false;
                })
                .catch(function(error) {
                    console.info(error)
                })
                .finally(function() {
                    that.isLoading[kind] = false;


                });

            this.isLoading['cities'] = false;
        },
        onSelect() {
            let that = this;
            setTimeout(function() { that.getData(); }, 100)

        },
        findCodes(query, kind) {
            console.info(query, kind)


            if (typeof window.LIT !== 'undefined') {
                clearTimeout(window.LIT);
            }
            window.LIT = setTimeout(() => {
                this.Request(query, kind)
            }, 100);


        },
        clearAll() {}
    }

}
</script>
<style lang="scss">
html,
body {
    font-family: "DIN Pro";
    padding: 0;
    margin: 0;
    font-size: 16px;
}

body {
    margin: 0px 16px;
}

* {
    box-sizing: border-box;
}

div.site-title-container {
    display: table;
    // width: 100%;
}

.site-title {
    display: table-cell;
    background: url(/favicon.ico) 10px 50% no-repeat;
    // width: 100%;
    height: 64px;
    padding: 4px 5px 5px 70px;
    vertical-align: middle;
    background-color: #fff;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
}

div.site-title input {
    border: 1px solid #f0f0f0;
    background-color: #fff;
    padding: 4px 6px;
    vertical-align: middle;
    margin-left: 8px;
    font-family: "DIN Pro";
    position: relative;
    top: -1px;
    margin-right: 6px;
    cursor: pointer;
}

div.filter {
    width: 100%;
    margin: 5px;
}

div.filter div.container {
    margin: 5px 0;
}

div.filter select {
    width: 95%;
}

div.debugger {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: #000;
    color: #fff;
}

table.companies {
    width: 100%;
    border-collapse: collapse;
}

table.companies th {
    font-weight: 1000;
    cursor: pointer;
}

table.companies th,
table.companies td {
    border: 0;
    padding: 4px 6px;
    text-align: left;
    border-bottom: 1px solid #f0f0f0;

}

table.companies td.nw {
    white-space: nowrap;
}

table.companies tr:hover {
    background-color: #f0f0f0;
}

table.companies .r {
    text-align: right;
}

table.companies tr.inactive td {
    color: #999;
}

div.overall {
    padding: 16px 4px;
    text-align: left;
    font-size: 18px;
    line-height: 22px;
}


fieldset[disabled] .multiselect {
    pointer-events: none
}

.multiselect__spinner {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 48px;
    height: 35px;
    background: #fff;
    display: block
}

.multiselect__spinner:after,
.multiselect__spinner:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border-color: #41b883 transparent transparent;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 0 0 1px transparent
}

.multiselect__spinner:before {
    animation: a 2.4s cubic-bezier(.41, .26, .2, .62);
    animation-iteration-count: infinite
}

.multiselect__spinner:after {
    animation: a 2.4s cubic-bezier(.51, .09, .21, .8);
    animation-iteration-count: infinite
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
    transition: opacity .4s ease-in-out;
    opacity: 1
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
    opacity: 0
}

.multiselect,
.multiselect__input,
.multiselect__single {
    font-family: inherit;
    font-size: 16px;
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

.multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 40px;
    text-align: left;
    color: #35495e
}

.multiselect * {
    box-sizing: border-box
}

.multiselect:focus {
    outline: none
}

.multiselect--disabled {
    opacity: .6
}

.multiselect--active {
    z-index: 1
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.multiselect--active .multiselect__select {
    transform: rotate(180deg)
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.multiselect__input,
.multiselect__single {
    position: relative;
    display: inline-block;
    min-height: 20px;
    line-height: 20px;
    border: none;
    border-radius: 5px;
    background: #fff;
    padding: 0 0 0 5px;
    width: 100%;
    transition: border .1s ease;
    box-sizing: border-box;
    margin-bottom: 8px;
    vertical-align: top
}

.multiselect__input::-webkit-input-placeholder {
    color: #35495e
}

.multiselect__input:-ms-input-placeholder {
    color: #35495e
}

.multiselect__input::placeholder {
    color: #35495e
}

.multiselect__tag~.multiselect__input,
.multiselect__tag~.multiselect__single {
    width: auto
}

.multiselect__input:hover,
.multiselect__single:hover {
    border-color: #cfcfcf
}

.multiselect__input:focus,
.multiselect__single:focus {
    border-color: #a8a8a8;
    outline: none
}

.multiselect__single {
    padding-left: 5px;
    margin-bottom: 8px
}

.multiselect__tags-wrap {
    display: inline
}

.multiselect__tags {
    min-height: 40px;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px
}

.multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: #41b883;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis
}

.multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all .2s ease;
    border-radius: 5px
}

.multiselect__tag-icon:after {
    content: "\D7";
    color: #266d4d;
    font-size: 14px
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
    background: #369a6e
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
    color: #fff
}

.multiselect__current {
    min-height: 40px;
    overflow: hidden;
    padding: 8px 12px 0;
    padding-right: 30px;
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #e8e8e8
}

.multiselect__current,
.multiselect__select {
    line-height: 16px;
    box-sizing: border-box;
    display: block;
    margin: 0;
    text-decoration: none;
    cursor: pointer
}

.multiselect__select {
    position: absolute;
    width: 40px;
    height: 38px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    text-align: center;
    transition: transform .2s ease
}

.multiselect__select:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #999;
    margin-top: 4px;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #999 transparent transparent;
    content: ""
}

.multiselect__placeholder {
    color: #adadad;
    display: inline-block;
    margin-bottom: 10px;
    padding-top: 2px
}

.multiselect--active .multiselect__placeholder {
    display: none
}

.multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1;
    -webkit-overflow-scrolling: touch
}

.multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top
}

.multiselect--above .multiselect__content-wrapper {
    bottom: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    border-top: 1px solid #e8e8e8
}

.multiselect__content::webkit-scrollbar {
    display: none
}

.multiselect__element {
    display: block
}

.multiselect__option {
    display: block;
    padding: 12px;
    min-height: 40px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: nowrap
}

.multiselect__option:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: 40px;
    padding-right: 12px;
    padding-left: 20px;
    font-size: 13px
}

.multiselect__option--highlight {
    background: #41b883;
    outline: none;
    color: #fff
}

.multiselect__option--highlight:after {
    content: attr(data-select);
    background: #41b883;
    color: #fff
}

.multiselect__option--selected {
    background: #f3f3f3;
    color: #35495e;
    font-weight: 700
}

.multiselect__option--selected:after {
    content: attr(data-selected);
    color: silver
}

.multiselect__option--selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff
}

.multiselect__option--selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #fff
}

.multiselect--disabled {
    background: #ededed;
    pointer-events: none
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
    background: #ededed;
    color: #a6a6a6
}

.multiselect__option--disabled {
    cursor: text;
    pointer-events: none
}

.multiselect__option--group {
    background: #ededed;
    color: #35495e
}

.multiselect__option--group.multiselect__option--highlight {
    background: #35495e;
    color: #fff
}

.multiselect__option--group.multiselect__option--highlight:after {
    background: #35495e
}

.multiselect__option--disabled.multiselect__option--highlight {
    background: #dedede
}

.multiselect__option--group-selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #fff
}

.multiselect-enter-active,
.multiselect-leave-active {
    transition: all .15s ease
}

.multiselect-enter,
.multiselect-leave-active {
    opacity: 0
}

.multiselect__strong {
    margin-bottom: 8px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top
}

[dir=rtl] .multiselect {
    text-align: right
}

[dir=rtl] .multiselect__select {
    right: auto;
    left: 1px
}

[dir=rtl] .multiselect__tags {
    padding: 8px 8px 0 40px
}

[dir=rtl] .multiselect__content {
    text-align: right
}

[dir=rtl] .multiselect__option:after {
    right: auto;
    left: 0
}

[dir=rtl] .multiselect__clear {
    right: auto;
    left: 12px
}

[dir=rtl] .multiselect__spinner {
    right: auto;
    left: 1px
}

@keyframes a {
    0% {
        transform: rotate(0)
    }

    to {
        transform: rotate(2turn)
    }
}



.language-json {
    color: #000;
    text-shadow: 0 1px #fff;
    font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

code[class*=language-],
pre[class*=language-] {
    color: #000;
    text-shadow: 0 1px #fff;
    font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

:not(pre)>code[class*=language-],
pre[class*=language-] {
    background: #f3f5f6;
}

.loader {
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    z-index: 10;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}

.loader.active {
    display: flex;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.chart_filter {
    font-size: 20px;
    line-height: normal;
    display: flex;
    justify-content: center;


}

.chart_filter_item.active {
    opacity: .4;
}

.chart_filter_item {
    margin: 0 10px;
    cursor: pointer;
}

.site-title .active {
    background: #41b883;
    color: #fff;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;

}

.nav_item {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px;
    margin: 5px 5px;
    cursor: pointer;
}

.nav_item:hover {
    opacity: .5;
}

.nav_item.active {
    opacity: .5;
    cursor: default;
    border-color: #000;
    color: #000;
}

.button {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 0px 10px;
    // margin: 0px 5px;
    display: inline-block;
    cursor: pointer;
    line-height: 28px;
}

.preset_area {
    border: 1px solid #fff;
    margin: 10px 0;
    display: flex;
}

.preset_area_item {
    display: flex;
    margin: 0px 10px;
}

.input {
    border: 1px solid #ddd;
    border-radius: 3px;
    line-height: 26px;
    // height: 30px;
    display: inline-block;
    // max-height: 30px;
    white-space: nowrap;
}

.button.remove {
    border-color: red;
}

.button__add_preset {
    margin-left: 5px;
}

.sorted {
    text-decoration: underline;


}

.company-item {
    cursor: pointer;
}

.company-detail-item {
    display: flex;
}

.company-detail-item-title,
.detail-list-sub {
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 5px;
}

.overall {
    overflow: scroll;
    position: relative;
    max-height: 80vh;
}


.detail-list ul {
    margin-left: 10px;
    margin-bottom: 10px
}

.detail-list .detail-list-item {
    margin-left: 15;
}
</style>