<template>
  <router-view/>
</template>

<style lang="scss">
  @font-face {
  font-family: "DIN Pro";
  src: local("din"),
   url(./fonts/dinpro.ttf) format("truetype");
}



@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro.eot');
    src: local('DIN Pro'), local('dinpro'),
        url('./fonts/dinpro.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro.woff2') format('woff2'),
        url('./fonts/dinpro.woff') format('woff'),
        url('./fonts/dinpro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro-bold.eot');
    src: local('DIN Pro Bold'), local('DINPro-Bold'),
        url('./fonts/dinpro-bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro-bold.woff2') format('woff2'),
        url('./fonts/dinpro-bold.woff') format('woff'),
        url('./fonts/dinpro-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro-medium.eot');
    src: local('DIN Pro Medium'), local('DINPro-Medium'),
        url('./fonts/dinpro-medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro-medium.woff2') format('woff2'),
        url('./fonts/dinpro-medium.woff') format('woff'),
        url('./fonts/dinpro-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}



html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "DIN Pro";
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

#app {
  margin: 0 16px;
}
</style>
