<template>
	<transition name="modal-animation">
		<div class="modal" v-show="modalActive">
			<transition name="modal-animation-inner">
				<div v-show="modalActive" class="modal-inner">
					<div class="modal-close" @click="close"></div>
					<slot/>
				</div>
			</transition>
		</div>
	</transition>
</template>
<script>
	export default {
		props:['modalActive'],
		setup(props, {emit}) {
			const close = () => {
				emit('close');
			}
			return { close };
		}
	}
</script>
<style>
	.modal {
		position: fixed;
		background: rgba(0,0,0,.5);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.modal-inner {
		background: #fff;
		padding: 20px 60px;
		border-radius: 10px;
		margin: 10px 20px;
		position: relative;
		width: calc(100% - 60px);
	}
	.modal-close {
		background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAAE+AAABPgEyZQqHAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAABVQTFRF////AAAAAAAAAAAAAAAAAAAAAAAAPYbscQAAAAZ0Uk5TAARUp6v7ZOI89wAAAIhJREFUKFNlkr0RgCAMhYMTWDCAd54LWNjbOICFC4jZfwSBQMjlUeRyeR8hPxCtM+kJO9H0nCMQv4U2ToqEi286mBWJzG8xHclAFsV2oGiKdEkRVZoz7jbPZK+uSS6IAQQxgCAWqIgFBLEABvwVSOqfhcJ86dCcbx8G5EcIQ4Y1wKJglbBs/x1+4i9HdZWYePkAAAAASUVORK5CYII=');
		width: 16px;
		height: 16px;
		display: block;
		position: absolute;
		right: 10px;
		top: 10px;
		cursor: pointer;
		background-size: cover;

	}
	.modal-header {
		text-align: left;
		margin-block: 10px;
		font-weight: 500;
		font-size: 20px;
	}
</style>